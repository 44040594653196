import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IAddress } from 'src/app/models/address';
import { TranslateApiPipe } from '../../translate-api/translate-api.pipe';

@Pipe({
  name: 'fullAddress'
})
export class FullAddressPipe implements PipeTransform {
  /** data | fullAddress: 'medium' */
  private readonly translate = inject(TranslateService);
  private readonly translateApi = inject(TranslateApiPipe);

  transform(value: IAddress): string {
    if (value) {
      let house: string = value?.house ? `${this.translate.instant('house_number') + value.house}, ` : '';
      let street: string = value?.street ? `${this.translate.instant('street') + value.street}, ` : '';
      let detail: string = house && street ? '' : `${value.detail}, ` || '';

      return `${house} ${street} ${detail} ${this.translateApi.transform(
        value.villages,
        this.translate.currentLang
      )}, ${this.translateApi.transform(value.communes, this.translate.currentLang)}, ${this.translateApi.transform(
        value.districts,
        this.translate.currentLang
      )}, ${this.translateApi.transform(value.city_provinces, this.translate.currentLang)}`;
    }
    return '';
  }
}
