import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from 'src/app/models/user';

@Pipe({
  name: 'fullName'
})
export class FullNamePipe implements PipeTransform {
  transform(user: any, lang?: string): string {
    let names = [];
    if (user === undefined) return '';
    let used_lang = lang === 'en' ? '_en' : '';

    if (user?.last_name) {
      let key: string = 'last_name' + used_lang;
      let getVal = user[key] == null || user[key] == '' ? user.last_name : user[key];
      names.push(getVal);
    }
    if (user?.first_name) {
      let key: string = 'first_name' + used_lang;
      let getVal = user[key] == null || user[key] == '' ? user.first_name : user[key];
      names.push(getVal);
    }

    return names.join(' ');
  }

  // transform(value: User | User[], id: string = ''): string {
  //   if (value) {
  //     let user: User | undefined;
  //     if (Array.isArray(value)) {
  //       user = (value as User[]).find(u => u._id == id);
  //     } else {
  //       user = value;
  //     }
  //     if (user) {
  //       let names = [];
  //       if (user.last_name) {
  //         names.push(user.last_name);
  //       }

  //       if (user.first_name) {
  //         names.push(user.first_name);
  //       }
  //       return names.join(' ');
  //     }
  //   }
  //   return '';
  // }
}
