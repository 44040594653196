import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class EmptyComponent implements OnInit {
  @Input() width!: number;
  @Input() height!: number;

  constructor() {}

  ngOnInit(): void {}
}
