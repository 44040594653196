export default class EnumConstant {
  public static readonly ACTIVE = 1;
  public static readonly INACTIVE = -1;
  public static readonly DRAFT = 2;
  public static readonly REQUESTING = 3;
  public static readonly REJECTED = -3;
}

export enum ETableStatus {
  ACTIVE = EnumConstant.ACTIVE,
  INACTIVE = EnumConstant.INACTIVE,
  PENDING = EnumConstant.REQUESTING
}

export enum ERole {
  ALL = 'all',
  ADMIN = 'admin',
  VIEWER = 'viewer'
}

export enum ERoleId {
  ADMIN = 1,
  VIEWER = 14
}

export enum EUserStatus {
  ACTIVE = EnumConstant.ACTIVE,
  INACTIVE = EnumConstant.INACTIVE
}

export enum EBaseKeyAddress {
  BASE_PROVINCE = 'baseProvince',
  BASE_DISTRICT = 'baseDistrict',
  BASE_COMMUNE = 'baseCommune',
  BASE_VILLAGE = 'baseVillage',
  BASE_CURRENT_PROVINCE = 'baseProvince',
  BASE_CURRENT_DISTRICT = 'baseCurrentDistrict',
  BASE_CURRENT_COMMUNE = 'baseCurrentCommune',
  BASE_CURRENT_VILLAGE = 'baseCurrentVillage'
}

export enum ECurrencyLabel {
  KHMER = 'រៀល',
  KM = '៛',
  ENGLISH = 'USD',
  EN = '$'
}

export enum EValidityType {
  YEAR = 1,
  MONTH = 2,
  DAY = 3
}

export enum ERequestStatus {
  APPROVED = 1,
  DRAFT = 2,
  REQUESTING = 3,
  CHANGE_DEPARTMENT = 4,
  CHANGE_SECTOR = 5,
  REJECTED = -3,
  MARK_AS_DELIVER = 6,
  RETURN_REQUEST = -2,
  SUSPENDED = -8
}

export enum ERequestFor {
  SELF = 1,
  OTHER = -1
}
